@import "@fontsource-variable/inter/index.css";
@import "react-datepicker/dist/react-datepicker.css";
@import "./date-picker.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --font-sans: "Inter Variable", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;

    --background-color: #f4f5f6;
    --color: #000;
    --secondary: #0000000a;
    --muted: #0000000f;
    --muted-foreground: #6b7280;
    --accent: #00000014;
    --accent-foreground: #111827;
    --background: #f4f5f6;
    --green: 34 197 94;
    --red: 239 68 68;
  }

  .paper {
    backdrop-filter: blur(12px) saturate(190%) contrast(50%) brightness(130%);
  }

  .dark {
    --secondary: #ffffff0a;
    --muted: #ffffff0f;
    --muted-foreground: #94a3b8;
    --accent: #ffffff14;
    --accent-foreground: #e2e8f0;
    --background-color: #0f172a;
    --color: #ffffff;
    --background: #0f172a;
    --green: 19 155 109;
    --red: 180 48 60;
  }

  .dark .paper {
    backdrop-filter: blur(10px) saturate(190%) contrast(70%) brightness(80%);
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  background-color: var(--background-color) !important;
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
  background-color: transparent !important;
}

footer {
  position: sticky;
  top: 100%;
}

html,
body {
  font-family: var(--font-sans);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color);
  overscroll-behavior-x: none;
}

#__next {
  min-height: 100vh;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

*.scroll {
  overflow-y: overlay !important;
  overflow-y: scroll;
}

*.scroll::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}

*.scroll::-webkit-scrollbar-thumb {
  height: 30px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 7px;
  -webkit-box-shadow: inset -1px -1px 0px rgba(255, 255, 255, 0.05), inset 1px
    1px 0px rgba(255, 255, 255, 0.05);
}

html[class="dark"] *.scroll::-webkit-scrollbar-thumb {
  height: 30px;
  border: 4px solid rgba(255, 255, 255, 0);
  background-clip: padding-box;
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-border-radius: 7px;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px
    rgba(0, 0, 0, 0.05);
}

*.scroll::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

*.scroll::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

#root-portal {
  pointer-events: all;
}

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  input[type="range"]::-webkit-slider-thumb {
    @apply appearance-none w-6 h-6 bg-blue rounded-full;
  }
}
